<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10"><h3>Order Daily Report</h3></v-col>
      <v-col cols="2">#{{ count }}</v-col>
    </v-row>

    <v-row justify="center">
      <v-btn
        fab
        color="cyan"
        absolute
        top
        class="mt-6"
        small
        right
        @click.native.stop="reload"
      >
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-row>
    <v-sheet elevation="1">
      <v-row v-if="allSellers" justify="center" class="px-8">
        <div v-for="seller in allSellers" :key="seller">
          <div class="pa-2 text-no-wrap">
            <v-icon :color="colors(seller)">mdi-rectangle</v-icon> {{ seller }}
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center px-8 py-4">
          <div v-resize="onResize" ref="svgContainer" class="w-100">
            <svg :width="width" :height="height" class="svgBar" />
          </div>
        </v-col>
      </v-row>
      <v-row class="px-8">
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-dialog
            ref="dialog"
            v-model="modal"
            persistent
            :return-value.sync="select_dates"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
              ></v-text-field>
            </template>
            <v-date-picker v-model="select_dates" range
              ><v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="onSelectDate">
                OK
              </v-btn></v-date-picker
            >
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="6" md="8" lg="9">
          <v-combobox
            v-model="producers"
            :items="allSellers"
            label="By Sellers"
            multiple
            chips
            hide-details="auto"
            dense
            class="pt-1"
          ></v-combobox>
        </v-col>
      </v-row>
    </v-sheet>
    <v-btn icon to="/report" class="ml-2">
      <v-icon color="info">arrow_back</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as d3 from 'd3'
import resize from 'vue-resize-directive'
import _ from 'lodash'

export default {
  directives: {
    resize
  },
  data() {
    return {
      orders: [],
      count: 0,
      width: 0,
      height: 400,
      modal: false,
      producers: [],
      select_dates: [
        d3.timeFormat('%Y-%m-%d')(Date.now()),
        d3.timeFormat('%Y-%m-%d')(Date.now())
      ],
      range_dates: [
        d3.timeFormat('%Y-%m-%d')(Date.now()),
        d3.timeFormat('%Y-%m-%d')(Date.now())
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    }),
    dateRangeText() {
      return this.range_dates.join(' ~ ')
    },
    allSellers() {
      return Object.keys(this.orders)
    },
    daysInRange() {
      let date = []
      let from = this.range_dates[0],
        to = this.range_dates[1]
      while (moment(from) <= moment(to)) {
        date.push(from)
        from = moment(from)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      return date
    },
    colors() {
      return d3
        .scaleOrdinal()
        .domain(this.allSellers)
        .range(this.randomHsl(this.allSellers.length))
    }
  },
  async mounted() {
    this.onResize()
    await this.reload()
  },
  watch: {
    range_dates() {
      this.reload()
    },
    producers() {
      this.renderChart()
    },
    width: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.renderChart()
      }
    }
  },
  methods: {
    onSelectDate() {
      this.$refs.dialog.save(this.select_dates)
      this.range_dates = this.select_dates
    },
    onResize() {
      this.width = this.$refs.svgContainer.clientWidth
    },
    randomHsl(size) {
      return [...Array(size).keys()].map(
        () => `hsla(${Math.random() * 540}, 100%, 50%, 1)`
      )
    },

    async reload() {
      await this.loadOrders()
      await this.renderChart()
    },
    async renderChart() {
      if (!this.orders) {
        console.warn(`orders is empty`)
        return
      }

      const days = this.daysInRange
      const sellers = this.allSellers
      //pre-processing
      let pre_data = []

      for (let [sellerName, info] of Object.entries(this.orders)) {
        info.forEach(e => {
          let record = {
            seller: sellerName,
            deliveryDate: e.deliveryDate
          }
          if (this.producers.length > 0) {
            if (this.producers.includes(sellerName)) {
              pre_data.push(record)
            }
          } else {
            pre_data.push(record)
          }
        })
      }

      let nested = d3
        .nest()
        .key(e => e.deliveryDate)
        .entries(pre_data)

      for (let item of nested) {
        item.values = d3
          .nest()
          .key(e => e.seller)
          .entries(item.values)
          .map(e => ({ ...e, freq: e.values.length }))
          .reduce((a, c) => {
            a[c.key] = c.freq
            return a
          }, {})
      }
      nested = nested
        .map(n => ({ day: n.key, ...n.values }))
        .sort((a, b) => new Date(a.day) - new Date(b.day))
      console.log(nested, 'nested!')

      const stackedData = d3.stack().keys(sellers)(nested)
      let maxY = 0

      stackedData.forEach(s => {
        let max = Math.max(...s.map(e => (isNaN(e[1]) ? 0 : e[1])))
        if (maxY < max) {
          maxY = max
        }
      })

      d3.select('.svgBar')
        .selectAll('*')
        .remove()

      const margin = { left: 30, right: 20, bottom: 20, top: 20 }
      const dateLength = 80
      const labelCounts = Math.floor(
        (this.width - margin.left - margin.right) / dateLength
      )
      const step = Math.ceil(days.length / labelCounts)
      const bars = d3
        .select('.svgBar')
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

      const x = d3
        .scaleBand()
        .domain(days)
        .range([0, this.width - margin.right - margin.left])
        .padding([0.2])

      bars
        .append('g')
        .attr(
          'transform',
          `translate(0,${this.height - margin.top - margin.bottom})`
        )
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .call(g => g.selectAll('line').remove())
        .call(g =>
          g
            .selectAll('text')
            .style('font-size', '14px')
            .text((d, i) => (i % step === 0 ? d : ''))
        )

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([0, maxY + 1])
        .range([this.height - margin.bottom - margin.top, 0])

      bars
        .append('g')
        .call(
          d3.axisLeft(y).tickSize(-(this.width - margin.left - margin.right))
        )
        .call(g => g.selectAll('line').style('stroke', 'lightgrey'))
        .call(g =>
          g
            .selectAll('text')
            .style('font-size', '14px')
            .text(d => (Number(d) !== Number(d.toFixed(0)) ? '' : d))
        )

      bars.selectAll('.domain').remove()

      let hoveredData
      const bar = bars
        .append('g')
        .selectAll('g')
        .data(stackedData)
        .enter()
        .append('g')
        .attr('fill', d => this.colors(d.key))
        .on('mouseover', function(d) {
          bar.append('title').text(`${d.key} : ${hoveredData[d.key]} order(s)`)
        })
        .on('mouseout', function() {
          bars.selectAll('title').remove()
        })

      bar
        .selectAll('rect')
        .data(e => e)
        .enter()
        .append('rect')
        .attr('x', d => x(d.data.day))
        .attr('y', d => y(d[1]))
        .attr('height', d => y(d[0] || 0) - y(d[1] || 0))
        .attr('width', x.bandwidth())
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .on('mouseover', function(d) {
          hoveredData = d.data
          d3.select(this)
            .style('stroke', 'grey')
            .style('stroke-width', 3)
            .style('fill-opacity', 0.6)
            .style('cursor', 'pointer')
        })
        .on('mouseout', function() {
          d3.select(this)
            .style('stroke', 'white')
            .style('stroke-width', 1)
            .style('fill-opacity', 1)
            .style('cursor', 'default')
        })

      d3.select('.svgBar')
        .append('text')
        .attr('y', '10px')
        .style('font-size', '12px')
        .text('Total Order Counts')
    },
    async loadOrders() {
      if (this.isAdmin) {
        if (!this.$route.params.uid) {
          await this.$store
            .dispatch('getOrdersFromRange', {
              from: this.range_dates[0],
              to: this.range_dates[1],
              range: this.daysInRange
            })
            .then(data => {
              this.count = data.length
              const list = _.chain(data)
                .groupBy('seller.businessName')
                .value()

              this.orders = list
            })
        }
      }
    }
  }
}
</script>
